<template>
    <div>
        <p>检测到本网站的登录二维码，是否继续登录？</p>
        <button @click="confirmLogin">确认登录</button>
        <button @click="cancelLogin">取消</button>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    methods: {
        confirmLogin() {
            axios.post('https://serve.study.chyt.top/api/login2',)
            .then(() => {
                alert('登录成功');
            }).catch(err => {
                alert('登录失败');
                console.error('登录请求失败:', err);
            });
        },
        cancelLogin() {
            alert('取消登录');
        }
    }
}
</script>