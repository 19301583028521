<template>
  <div class="scanner-container">
    <div id="reader" class="reader"></div> <!-- QR 码读取器 -->
    <div class="scan-line"></div> <!-- 上下扫描动画 -->
    <p class="status">{{ statusMessage }}</p> <!-- 状态消息 -->
    <button @click="goBack" class="close-btn">关闭</button> <!-- 关闭按钮 -->
    <div v-if="showPrompt" class="login-prompt">
      <p>检测到本网站的登录二维码，是否继续登录？</p>
      <button @click="confirmLogin">确认登录</button>
      <button @click="cancelLogin">取消</button>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";

export default {
  data() {
    return {
      statusMessage: "请对准二维码进行扫描", // 状态提示消息
      html5QrCode: null, // Html5Qrcode 实例
      showPrompt: false, // 控制登录确认框的显示
      qrData: null, // 存储二维码解码后的数据
      uid: 'id_1716278963526',
    };
  },
  mounted() {
    this.initScanner(); // 页面加载时初始化扫描器
  },
  beforeDestroy() {
    this.stopScanner(); // 页面销毁时停止摄像头
  },
  methods: {
    // 初始化 QR 扫描器
    initScanner() {
      this.html5QrCode = new Html5Qrcode("reader");
      this.html5QrCode
        .start(
          { facingMode: "environment" }, // 使用后置摄像头
          {
            fps: 10, // 每秒帧数
          },
          this.onScanSuccess, // 扫描成功回调
          this.onScanError // 扫描错误回调
        )
        .catch((err) => {
          console.error("摄像头初始化失败:", err);
          this.statusMessage = "无法访问摄像头，请检查权限。";
        });
    },

    // 扫描成功回调
    onScanSuccess(decodedText) {
      console.log(`扫描成功: ${decodedText}`);
      try {
        const urlParams = new URLSearchParams(new URL(decodedText).search);
        const sessionId = urlParams.get('sessionId');
        const identifier = urlParams.get('identifier');

        // 如果 identifier 是我们网站的标识符，则触发登录流程
        if (identifier === 'chyt-website-login') {
          this.qrData = { sessionId, identifier };
          axios.post('https://serve.study.chyt.top/api/scanQRCode', {
            sessionId: this.qrData.sessionId,
            uid: this.uid,
          }).then(() => {
            this.showPrompt = true; // 显示登录确认框
          }).catch(err => {
            console.error('登录请求失败:', err);
          });
        } else {
          // 如果没有标识符，跳转到扫描到的 URL
          window.location.href = decodedText;
        }
      } catch (err) {
        console.error('二维码解码错误:', err);
      }
    },

    // 扫描错误回调
    onScanError() {
      this.statusMessage = "请对准二维码...";
    },

    // 停止摄像头并关闭扫描器
    stopScanner() {
      if (this.html5QrCode) {
        this.html5QrCode.stop().then(() => {
          console.log("摄像头已关闭");
        }).catch((err) => {
          console.error("关闭摄像头失败:", err);
        });
      }
    },

    confirmLogin() {
      axios.post('https://serve.study.chyt.top/api/login2',{
        sessionId: this.qrData.sessionId,
      })
        .then(() => {
          alert('登录成功');
        }).catch(err => {
          alert('登录失败');
          console.error('登录请求失败:', err);
        });
    },

    cancelLogin() {
      alert('取消登录');
    },

    // 返回主页面
    goBack() {
      this.stopScanner(); // 停止扫描器
      this.$router.push('/'); // 跳转回主页
    },
  },
};
</script>

<style scoped>
.scanner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reader {
  position: relative;
  width: 100%;
  height: 100%;
}

.scan-line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 50px;
  animation: scanAnimation 3s infinite linear;
}

.scan-line::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent 0%, #1ba784 50%, transparent 100%);
}

@keyframes scanAnimation {
  0% {
    opacity: 0.5;
    top: 10%;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    top: 90%;
    opacity: 0;
  }
}

.status {
  color: white;
  margin-top: 20px;
  font-size: 18px;
}

.close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
}

.login-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

button {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
</style>