import Vue from 'vue';
import Router from 'vue-router';
import QRCodeScanner from '@/components/QRCodeScanner.vue'; // 扫码页面
import HomePage from '@/components/HomePage.vue'; // 主页面
import ComfirmPage from '@/components/ComfirmPage.vue'; // 确认页面

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage, // 主页面
    },
    {
      path: '/scan',
      name: 'QRCodeScanner',
      component: QRCodeScanner, // 扫码页面
    },
    {
      path: '/comfirm',
      name: 'ComfirmPage',
      component: ComfirmPage // 确认页面
    }
  ],
});
