<template>
  <div class="home">
    <h1>欢迎来到扫码登录系统</h1>
    <button @click="goToScanner" class="scan-btn">打开扫一扫</button>
  </div>
</template>

<script>
export default {
  methods: {
    goToScanner() {
      this.$router.push('/scan'); // 跳转到扫码页面
    },
  },
};
</script>

<style scoped>
.scan-btn {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
</style>
